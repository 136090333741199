<template>
  <FornitoriDesktop v-if="!$vuetify.breakpoint.mobile" />
  <v-row justify="center" class="mt-12" v-else>Non disponibile per mobile</v-row>
</template>

<script>
import FornitoriDesktop from "@/components/Fornitori/desktop/fornitori_desktop.vue"
export default {
  components: {
    FornitoriDesktop
  },
};
</script>