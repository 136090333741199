<template>
  <v-card>
    <v-card-title class="grey--text text-subtitle-1">
      Fornitori
      <v-spacer />
      <NuovoFornitore />
    </v-card-title>

    <v-card-text class="pb-8">
      
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="search_text"
            outlined
            placeholder="Cerca Fornitore ..."
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="2" class="pl-2"> Denominazione </v-col>
        <v-col cols="1"> Codice </v-col>
        <v-col cols="2"> Indirizzo </v-col>
        <v-col cols="2"> Città </v-col>
        <v-col cols="2"> Telefono </v-col>
        <v-col cols="2"> E-mail </v-col>
      </v-row>
      <v-divider class="mt-4"/>
      <template v-for="fornitore in filtered">
        <FornitoreElement :fornitore="fornitore" :key="fornitore.codice" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import FornitoreElement from "./fornitore_element.vue";
import NuovoFornitore from "./fornitore_nuovo";
export default {
  components: {
    FornitoreElement,
    NuovoFornitore,
  },
  data() {
    return {
      search_text: "",
    };
  },
  computed: {
    fornitori() {
      return this.$store.getters.get_fornitori;
    },
    filtered() {
      if (this.search_text.length > 2) {
        let regex = new RegExp(this.search_text, "i");
        return this.fornitori
          .filter((fornitore) => {
            return regex.test(fornitore.nome);
          })
          .sort((a, b) => {
            return a - b;
          });
      } else {
        return this.fornitori;
      }
    },
  },
};
</script>