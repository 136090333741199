<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-row align="center" no-gutters class="py-3" :class="hover ? 'green lighten-5' : ''">
        <v-col cols="2" class="pl-2"
          ><v-avatar size="30" class="mr-4">
            <v-img :src="avatar" />
          </v-avatar>
          <b>{{ fornitore.nome }}</b>
        </v-col>
        <v-col cols="1">
          {{ fornitore.codice }}
        </v-col>
        <v-col cols="2">
          {{ fornitore.indirizzo }}
        </v-col>
        <v-col cols="2">
          {{ fornitore.citta }}
        </v-col>
        <v-col cols="2">
          {{ fornitore.telefono }}
        </v-col>
        <v-col cols="2">
          {{ fornitore.email }}
        </v-col>
        <v-col cols="1">
          <EditFornitore :fornitore="fornitore" :show="hover" />
          <v-dialog v-model="dialog" width="400" >
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" v-show="hover" color="red">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="red--text"> Sei sicuro ? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" @click="dialog = false">
                  Annulla
                </v-btn>
                <v-btn outlined color="red" :loading="loading" @click="elimina">
                  Elimina
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-hover>
    <v-divider />
  </div>
</template>

<script>
 import EditFornitore from './fornitore_modifica.vue'
export default {
  components: {
    EditFornitore
  },
  props: {
    fornitore: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    avatar() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/fornitore/" +
        this.fornitore.id +
        "/avatar" +
        "?t=" +
        this.fornitore.logo_md5
      );
    },
  },
  methods: {
    elimina() {
      this.loading = true;
      this.$store
        .dispatch("delete_fornitore", this.fornitore.id)
        .then((res) => {
          if (res == "bound") {
            alert("Produttore collegato a un lotto");
          }
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
        });
    },
  },
};
</script>